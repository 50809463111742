@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Akzidenz_Grotesk_Bold';
  src: url('./assets/fonts/akzidenzgroteskbe-bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/akzidenzgroteskbe-bold-webfont.woff') format('woff');
  font-weight: bold;
  
}

@font-face {
  font-family: 'Akzidenz_Grotesk_Light';
  src: url('./assets/fonts/akzidenzgroteskbe-light-webfont.woff2') format('woff2'),
       url('./assets/fonts/akzidenzgroteskbe-light-webfont.woff') format('woff');
  font-weight: light;
  
}

@font-face {
  font-family: 'Akzidenz_Grotesk_Medium';
  src: url('./assets/fonts/akzidenzgroteskbe-md-webfont.woff2') format('woff2'),
       url('./assets/fonts/akzidenzgroteskbe-md-webfont.woff') format('woff');
  font-weight: medium;
  
}

@font-face {
  font-family: 'Akzidenz_Grotesk_Regular';
  src: url('./assets/fonts/akzidenzgroteskbe-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/akzidenzgroteskbe-regular-webfont.woff') format('woff');
  font-weight: normal;
  
}

@font-face {
  font-family: 'Akzidenz_Grotesk_Super';
  src: url('./assets/fonts/akzidenzgroteskbe-super-webfont.woff2') format('woff2'),
       url('./assets/fonts/akzidenzgroteskbe-super-webfont.woff') format('woff');
  font-weight: super;
  
}

body {
  font-family: 'Akzidenz_Grotesk_Regular';
  font-size: 1rem;
}
/*
h1 {
  font-family: 'Akzidenz_Grotesk_Bold';
  font-size: 2.5rem;
}

h2 {
  font-family: 'Akzidenz_Grotesk_Bold';
  font-size: 2rem;
}

h3 {
  font-family: 'Akzidenz_Grotesk_Bold';
  font-size: 1.5rem;
}

h4 {
  font-family: 'Akzidenz_Grotesk_Bold';
  font-size: 1.25rem;
}

h5 {
  font-family: 'Akzidenz_Grotesk_Bold';
  font-size: 1rem;
}

h6 {
  font-family: 'Akzidenz_Grotesk_Bold';
  font-size: 0.875rem;
}

p {
  font-family: 'Akzidenz_Grotesk_Regular';
  font-size: 1rem;
}

*/